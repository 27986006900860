import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "@layouts/index"
import SEO from "@components/seo"

import pageCss from "@css/components/common/page.module.styl"
import missionCss from "@css/components/recruit/mission.module.styl"
import memberCss from "@css/components/recruit/member.module.styl"
import wantedCss from "@css/components/recruit/wanted.module.styl"

import Footer from '@components/Footer'

class Privacy extends React.PureComponent {
    render(){
        const { data } = this.props
        const { bg01 } = data
        return(
            <div>
                <SEO title="資金決済に関する法律に基づく表示" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <h1 className={pageCss.title_mid}>
                                    資金決済に関する法律に基づく表示
                                </h1>
                            </div>
                            <div className={pageCss.term}>
                                <section>
                                    <h2>資金決済に関する法律に基づく表示</h2>
                                    <section>
                                        <h3>前払式支払手段の名称</h3>
                                        <p>
                                        SUGARコイン
                                        </p>
                                    </section>
                                    <section>
                                        <h3>氏名、商号又は名称</h3>
                                        <p>
                                        SUGAR株式会社
                                        </p>
                                    </section>
                                    <section>
                                        <h3>前払式支払手段（SUGARコイン）の支払可能金額等</h3>
                                        <p>
                                        SUGARコインを保有できる上限の定めはありません。但し「SUGAR」利用規約に基づき上限を設定する場合があり、その場合にはその上限が適用されます。
                                        </p>
                                    </section>
                                    <section>
                                        <h3>前払式支払手段の有効期間・有効期限</h3>
                                        <p>
                                        有効期間・有効期限の定めはありません。但し「SUGAR」利用規約の失効条項に該当する場合には失効します。
                                        </p>
                                    </section>
                                    <section>
                                        <h3>お問い合わせ先</h3>
                                        <p>
                                        名　称：SUGAR株式会社<br/>
                                        住　所：〒107-0061 東京都港区北青山3-6-7 青山パラシオタワー11F<br/>
                                        代表者：鎌田和樹<br/>
                                        連絡先：legal@sugarcorp.zendesk.com<br/>
                                        電　話：弊社電話番号はお客様からのご要望に基づき遅滞なく電子メールにて通知いたします。弊社電話番号が必要なお客様は上記アドレスよりお申し出ください。<br/>
                                        </p>
                                    </section>
                                    <section>
                                        <h3>前払式支払手段を使用する事ができる範囲</h3>
                                        <p>
                                        SUGAR株式会社が提供する「SUGAR」内でのデジタルコンテンツの購入の代金決済にご利用いただけます。
                                        </p>
                                    </section>
                                    <section>
                                        <h3>前払式支払手段の利用上の必要な注意</h3>
                                        <p>
                                        動作環境その他の注意事項については、「SUGAR」利用規約をご確認ください。
                                        </p>
                                    </section>
                                    <section>
                                        <h3>前払式支払手段の未使用残高の確認方法</h3>
                                        <p>
                                        「SUGAR」のアプリ画面においてご確認いただけます。
                                        </p>
                                    </section>
                                    <section>
                                        <h3>前払式支払手段の利用に関する約款</h3>
                                        <p>
                                        SUGARコインには「SUGAR」利用規約が適用されます。
                                        </p>
                                    </section>
                                    <section>
                                        <p>
                                        以上<br/>
                                        <br/>
                                        制定日：2019年08月01日<br/>
                                        最終改訂日：2020年02月10日
                                        </p>
                                    </section>
                                </section>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default Privacy


export const query = graphql`
    query {
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-02@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
